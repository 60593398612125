<template>
  <v-row class="margin-top-35">
    <v-col :sm="smCols" :offset-sm="smOffset">
      <v-btn v-if="!noBack" @click="back" color="secondary" block large ripple outlined class="font-600">
        {{$t('estates.form.back')}}
      </v-btn>
    </v-col>
    <v-col :sm="smCols">
      <v-btn @click="next" color="primary" block large ripple class="font-600" v-text="nextBtnText()" :disabled="disabled" :loading="loadingNext"/>
    </v-col>
  </v-row>
</template>

<script>
import stepperEmit from '@/common/mixins/stepper-emit-mixin.js'

export default {
  name: 'FormButtons',

  props: {
    noBack: {
      type: Boolean,
      default: false
    },
    nextAsDone: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  mixins: [stepperEmit],

  computed: {
    smCols () {
      return (this.$vuetify.breakpoint.smAndUp && !this.noBack) ? 3 : 4
    },
    smOffset () {
      return (this.$vuetify.breakpoint.smAndUp && !this.noBack) ? 3 : 0
    }
  },

  methods: {
    nextBtnText () {
      return this.nextAsDone ? this.$t('estates.form.done') : this.$t('estates.form.next')
    }
  }
}
</script>
