<template>
  <v-text-field
    v-model="value.general_address"
    :rules="rules"
    outlined
    :append-icon="icon"
    dense
    placeholder=""
    :label="label"
    :hint="hint"
    :persistent-hint="true"
    @change="addressChanged"
    ref="field"/>
</template>

<script>
export default {

  name: 'GoogleAutoComplete',

  props: {
    value: Object,
    rules: Array,
    label: String,
    hint: String,
    icon: {
      type: String,
      default: 'mdi-map-marker'
    }
  },

  data () {
    return {
      gglAddress: null,
      autocomplete: null
    }
  },

  methods: {
    async setAutoComplete () {
      this.autocomplete = await this.$maps.Autocomplete(this.$refs.field.$refs.input)
      await this.$maps.addListener(this.autocomplete, 'place_changed', () => {
        const place = this.autocomplete.getPlace()
        if (!place) {
          this.gglAddress = false
          return null
        }
        this.gglAddress = true
        this.address.general_address = place.formatted_address
        this.setAddress(this.pullAddressDataFromPlaceObject(place))
      })
    },

    async addressChanged () {
      if (!this.gglAddress) {
        const geocoder = await this.$maps.Geocoder()
        geocoder.geocode({ address: this.address.general_address }, (results, status) => {
          this.setAddress(this.pullAddressDataFromPlaceObject(results[0]))
        })
      }
      this.gglAddress = false
    },

    pullAddressDataFromPlaceObject (place) {
      var addressComponent, locality, route, streetNumber, countryObj, countryCode, country, location, administrativeArea
      if (!place || !Object.keys(place).length) {
        return null
      }

      addressComponent = place.address_components

      locality = addressComponent.find((c) => { return c.types.includes('locality') })
      locality = locality ? locality.long_name : ''

      route = addressComponent.find((c) => { return c.types.includes('route') })
      route = route ? route.long_name : ''

      streetNumber = addressComponent.find((c) => { return c.types.includes('street_number') })
      streetNumber = streetNumber ? streetNumber.long_name : ''

      administrativeArea = addressComponent.filter((c) => { return c.types.toString().includes('administrative_area') })
      administrativeArea = administrativeArea ? administrativeArea.map((a) => a.long_name) : ''

      countryObj = addressComponent.find((c) => { return c.types.includes('country') })
      country = countryObj ? countryObj.long_name : ''
      countryCode = countryObj ? countryObj.short_name : ''

      location = {
        lat: place.geometry ? place.geometry.location.lat() : '',
        lon: place.geometry ? place.geometry.location.lng() : ''
      }

      return {
        locality,
        route,
        streetNumber,
        country,
        countryCode,
        location,
        administrativeArea
      }
    },

    setAddress (place) {
      if (place && this.address.general_address.includes(place.route) && this.address.general_address.includes(place.locality)) {
        this.address.city = place.locality ? place.locality : ''
        this.address.street = place.route ? place.route : ''
        this.address.number = place.streetNumber ? place.streetNumber : ''
        this.address.state = place.country ? place.country : ''
        this.address.country_code = place.countryCode ? place.countryCode : ''
        this.address.administrativeArea = place.administrativeArea ? place.administrativeArea : ''
        this.address.location = { lat: place.location.lat || '', lon: place.location.lon || '' }

        this.$emit('input', this.address)
      }
    }
  },

  created () {
    this.address = this.value
  },

  async mounted () {
    this.setAutoComplete()
  }
}
</script>

<style lang="scss" scoped>
</style>
