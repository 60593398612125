// v-model for emiting an object changes in current step to the parent component up to toppest component
const emitMixin = {
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      loadingNext: false
    }
  },

  methods: {
    async next () {
      this.loadingNext = true
      if (this.value) {
        await this.$emit('input', this.value)
        await new Promise(resolve => setTimeout(resolve, 100)) // let the parent watchers update the view before switching
      }
      await this.$emit('next')

      this.loadingNext = false
      // console.log(this, this.loadingNext)
    },
    async back () {
      if (this.value) {
        await this.$emit('input', this.value)
        await new Promise(resolve => setTimeout(resolve, 100)) // let the parent watchers update the view before switching
      }
      this.$emit('back')
    }
  }
}

export default emitMixin
