<template>
  <section class="estate-images">
    <h3 class="font-24">{{ $t('estates.form.upload_images') }}</h3>

    <v-row wrap :key="estate.assets.length">

      <template v-if="fullUpload">
        <!-- video button (only if no video) -->
        <v-col cols="12" sm="6" md="4" v-if="widgetLoaded && !videos.length">
          <v-btn  elevation="4"
                  color="bright darken-1"
                  height="200"
                  block
                  @click="uploadVideo">

            <div>
              <v-icon  class="icon-3xl">mdi-video-outline</v-icon>
              <div class="font-xl">{{ $t('estates.form.select_video') }}</div>
            </div>

          </v-btn>
        </v-col>

        <!-- videos -->
        <v-col cols="12" sm="6" md="4" v-for="video in videos" :key="video.id">
          <div class="estate-video">
            <cld-video :publicId="video.provider_id" width="100%" height="200" controls="true"/>

            <v-btn  color="secondary lighten-2"
                    @click="deleteAsset(video.id)">
             {{ $t('common.remove') }}
            </v-btn>
          </div>
        </v-col>

        <!-- virtualTour button (only if no virtualTour) -->
        <v-col cols="12" sm="6" md="4" v-if="widgetLoaded && !virtualTours.length">
          <v-btn  elevation="4"
                  color="bright darken-1"
                  height="200"
                  block
                  @click="vTourDialog = true">

            <div>
              <v-icon  class="icon-3xl">mdi-rotate-3d</v-icon>
              <div class="font-xl">{{ $t('estates.form.add_virtual_tour') }}</div>
            </div>
          </v-btn>
        </v-col>

        <!-- virtualTour -->
        <v-col cols="12" sm="6" md="4" v-for="vt in virtualTours" :key="vt.id">
          <div class="estate-image">
            <iframe :src="vt.url"
                    height="200"
                    width="100%"
                    transition="slide-y-transition"/>

            <v-btn  color="secondary lighten-2"
                    @click="deleteAsset(vt.id)">
             {{ $t('common.remove') }}
            </v-btn>
          </div>
        </v-col>

        <!-- floor plan button (only if no floor plan) -->
        <v-col cols="12" sm="6" md="4" v-if="widgetLoaded && !floorPlans.length">
          <v-btn  elevation="4"
                  color="bright darken-1"
                  height="200"
                  block
                  @click="uploadImages('floorPlan')">

            <div>
              <v-icon  class="icon-3xl">mdi-floor-plan</v-icon>
              <div class="font-xl">{{ $t('estates.form.select_floor_plan') }}</div>
            </div>
          </v-btn>
        </v-col>

        <!-- floor plan -->
        <v-col cols="12" sm="6" md="4" v-for="fp in floorPlans" :key="fp.id">
          <div class="estate-image">
            <v-img :src="$cloudinary.url(fp.provider_id, {height: 200, crop: 'fit'})"
                    aspect-ratio="1.7"
                    height="100%"
                    max-height="200px"
                    transition="slide-y-transition"/>

            <v-btn  color="secondary lighten-2"
                    @click="deleteAsset(fp.id)">
             {{ $t('common.remove') }}
            </v-btn>
          </div>
        </v-col>

        <!-- Tabu button (only if no Tabu) -->
        <v-col cols="12" sm="6" md="4" v-if="widgetLoaded && !tabu.length">
          <v-btn  elevation="4"
                  color="bright darken-1"
                  height="200"
                  block
                  @click="uploadImages('tabu')">

            <div>
              <v-icon  class="icon-3xl">mdi-cash</v-icon>
              <div class="font-xl">{{ $t('estates.form.select_tabu') }}</div>
            </div>
          </v-btn>
        </v-col>

        <!-- Tabu -->
        <v-col cols="12" sm="6" md="4" v-for="tbu in tabu" :key="tbu.id">
          <div class="estate-image">
            <v-img :src="$cloudinary.url(tbu.provider_id, {height: 200, crop: 'fit'})"
                    aspect-ratio="1.7"
                    height="100%"
                    max-height="200px"
                    transition="slide-y-transition"/>

            <v-btn  color="secondary lighten-2"
                    @click="deleteAsset(tbu.id)">
             {{ $t('common.remove') }}
            </v-btn>
          </div>
        </v-col>

        <!-- Property tax button (only if no property tax) -->
        <v-col cols="12" sm="6" md="4" v-if="widgetLoaded && !propertyTax.length">
          <v-btn  elevation="4"
                  color="bright darken-1"
                  height="200"
                  block
                  @click="uploadImages('propertyTax')">

            <div>
              <v-icon  class="icon-3xl">mdi-cash</v-icon>
              <div class="font-xl">{{ $t('estates.form.select_property_tax') }}</div>
            </div>
          </v-btn>
        </v-col>

        <!-- Property tax -->
        <v-col cols="12" sm="6" md="4" v-for="pt in propertyTax" :key="pt.id">
          <div class="estate-image">
            <v-img :src="$cloudinary.url(pt.provider_id, {height: 200, crop: 'fit'})"
                    aspect-ratio="1.7"
                    height="100%"
                    max-height="200px"
                    transition="slide-y-transition"/>

            <v-btn  color="secondary lighten-2"
                    @click="deleteAsset(pt.id)">
             {{ $t('common.remove') }}
            </v-btn>
          </div>
        </v-col>

        <!-- Assessment button (only if no assessment) -->
        <v-col cols="12" sm="6" md="4" v-if="widgetLoaded && !assessments.length">
          <v-btn  elevation="4"
                  color="bright darken-1"
                  height="200"
                  block
                  @click="uploadPdf('assessment')">

            <div>
              <v-icon class="icon-3xl">mdi-compare-vertical</v-icon>
              <div class="font-xl">{{ $t('estates.form.select_assessment') }}</div>
            </div>
          </v-btn>
        </v-col>

        <!-- Assessment -->
        <v-col cols="12" sm="6" md="4" v-for="assessment in assessments" :key="assessment.id">
          <div class="estate-image">
            <v-img :src="`${$cloudinary.url(assessment.provider_id, {height: 200, crop: 'fit'})}.webp`"
                    aspect-ratio="1.7"
                    height="100%"
                    max-height="200px"
                    transition="slide-y-transition"/>

            <v-btn  color="secondary lighten-2"
                    @click="deleteAsset(assessment.id)">
             {{ $t('common.remove') }}
            </v-btn>
          </div>
        </v-col>
      </template>

      <!-- images -->
      <v-col cols="12" sm="6" md="4" v-for="img in images" :key="img.id">
        <div class="estate-image">
          <v-img :src="$cloudinary.url(img.provider_id, {height: 200, crop: 'fit'})"
                  aspect-ratio="1.7"
                  height="100%"
                  max-height="200px"
                  transition="slide-y-transition"/>

          <v-btn  color="secondary lighten-2"
                  @click="deleteAsset(img.id)">
           {{ $t('common.remove') }}
          </v-btn>
        </div>
      </v-col>

      <!-- images button -->
      <v-col cols="12" sm="6" md="4" v-if="canUploadAnotherImage">
        <v-btn  elevation="4"
                color="bright darken-1"
                height="200"
                block
                @click="uploadImages()">

          <div>
            <v-icon class="icon-3xl">mdi-image-multiple-outline</v-icon>
            <div class="font-xl">{{ $t('estates.form.select_images') }}</div>
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="vTourDialog"
      width="450"
      @click:outside="clearVtour"
      transition="dialog-transition">

      <v-form ref="vTourFrom" class="padding-20">

        <h3 class="padding-bottom-5">{{ $t('estates.form.add_virtual_tour_link') }}</h3>
        <v-select v-model="vTourProvider" :items="['rollinom', 'metter']"/>
        <v-text-field
              dense
              outlined
              name="v_tour"
              :rules="[rules.required]"
              :label="$t('estates.form.virtual_tour_label')"
              v-model="vTourLink"/>

        <v-btn color="primary" @click="saveVtour">{{ $t('common.save') }}</v-btn>
        <v-btn text color="primary" @click="clearVtour">{{ $t('common.cancel') }}</v-btn>
      </v-form>
    </v-dialog>

    <FormButtons @next="next" noBack next-as-done v-model="value" :disabled="loading"/>

  </section>
</template>

<script>
import stepperEmit from '@/common/mixins/stepper-emit-mixin.js'
import FormButtons from '@/app/modules/estates/components/form-components/form-buttons'
import EstatesService from '@/app/modules/estates/estates-service'
import cloudinaryWidget from '@/common/mixins/cloudinary-widget-mixin.js'
import rules from '@/common/mixins/validations-rules-mixin.js'
import { mapGetters } from 'vuex'

export default {

  name: 'EstateImages',

  components: {
    FormButtons
  },

  mixins: [stepperEmit, cloudinaryWidget, rules],

  data () {
    return {
      estate: this.value,
      vTourLink: '',
      vTourDialog: false,
      vTourProvider: 'rollinom'
    }
  },

  computed: {
    ...mapGetters('user', ['isAgent']),

    fullUpload () {
      return this.isAgent
    },

    images () {
      return this.estate.assets.filter((a) => a.asset_type === 'image') || []
    },

    videos () {
      return this.estate.assets.filter((a) => a.asset_type === 'video') || []
    },

    floorPlans () {
      return this.estate.assets.filter((a) => a.asset_type === 'floorPlan') || []
    },

    tabu () {
      return this.estate.assets.filter((a) => a.asset_type === 'tabu') || []
    },

    propertyTax () {
      return this.estate.assets.filter((a) => a.asset_type === 'propertyTax') || []
    },

    virtualTours () {
      return this.estate.assets.filter((a) => a.asset_type === 'virtualTour') || []
    },

    assessments () {
      return this.estate.assets.filter((a) => a.asset_type === 'assessment') || []
    },

    maxImagesRiched () {
      return this.images.length < 4
    },

    canUploadAnotherImage () {
      return (this.widgetLoaded && this.maxImagesRiched && !this.isAgent) || (this.widgetLoaded && this.isAgent)
    }
  },

  methods: {
    async saveUplodes (files) {
      let assets = []
      files.forEach((f) => {
        if (!f.uploadInfo) return
        assets.push({
          provider: 'cloudinary',
          provider_id: f.uploadInfo.public_id,
          asset_type: this.uploadType,
          url: this.$cloudinary.url(f.uploadInfo.public_id),
          extra_data: f.uploadInfo.pages > 1 ? JSON.stringify({ pages: f.uploadInfo.pages }) : ''
        })
      })

      if (assets.length) {
        await this.saveAssets(assets)
        return true
      } else {
        return false
      }
    },

    async saveAssets (assets) {
      try {
        const res = await EstatesService.saveAssets(this.value.id, assets)
        this.estate.assets = res
      } catch (e) {
        console.error(e)
      }
    },

    async deleteAsset (id) {
      try {
        await EstatesService.deleteAssets(this.value.id, [id])
        const deletedAsset = this.estate.assets.find(ast => ast.id === id)
        const i = this.estate.assets.indexOf(deletedAsset)
        this.estate.assets.splice(i, 1)
      } catch (e) {
        console.error(e)
      }
    },

    async saveVtour () {
      let providerId = null
      switch (this.vTourProvider) {
        case 'rollinom':
          providerId = this.vTourLink.split('id=')[1]
          break
        case 'metter':
          providerId = this.vTourLink.split('/').slice(-1).toString()
      }

      const vTour = [{
        provider: this.vTourProvider,
        provider_id: providerId,
        asset_type: 'virtualTour',
        url: this.vTourLink
      }]
      await this.saveAssets(vTour)
      this.clearVtour()
    },

    clearVtour () {
      this.vTourDialog = false
      // this.vTourLink = ''
      this.$refs.vTourFrom.reset()
    }
  },

  created () {
    this.folder = `estates${process.env.NODE_ENV === 'development' ? '_dev' : ''}/${this.estate.id.replace('::', '')}`
  }
}

</script>

<style lang="scss" scoped>

  .estate-image,
  .estate-video {
    position: relative;

    .v-btn {
      display: none;
      position: absolute;
      width:100%;
      z-index: 2;
      top: -1000px;
      right: -1000px;
      bottom: -1000px;
      left: -1000px;
      margin: auto;
    }

    iframe {
      border: 0;
    }

    &:hover {

      .v-btn {
        display: block;
      }
    }
  }

  .estate-image {

    &:hover {

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255, .4);
        z-index: 1;
      }
    }
  }
</style>
