
export default {
  data () {
    return {
      dealTypeItems: [
        {
          text: this.$t('estates.form.sale'),
          value: 'sale'
        },
        {
          text: this.$t('estates.form.rent'),
          value: 'rent'
        }
      ]
    }
  }
}
