<template>
  <v-form ref="form">

    <section class="margin-bottom-35">

      <h3 class="font-24">{{ $t('estates.form.in_your_words') }}</h3>

      <div>

        <!-- Title -->
        <v-text-field
          dense
          outlined
          name="title"
          :rules="[rules.required]"
          @focus="titleTuched = true"
          :label="$t('estates.title')"
          v-model="estate.title"/>

        <!-- about -->
        <v-textarea
          v-if="fullUpload"
          no-resize
          outlined
          :label="$t('estates.about')"
          rows="3"
          counter
          maxlength="250"
          v-model="estate.about"/>

        <!-- Description -->
        <v-textarea
          no-resize
          auto-grow
          rows="5"
          outlined
          counter
          maxlength="1000"
          :label="$t('estates.description')"
          v-model="estate.description"/>

        <!-- about owner -->
        <v-textarea
          v-if="fullUpload"
          no-resize
          outlined
          counter
          maxlength="250"
          :label="$t('estates.owner_about')"
          rows="3"
          v-model="estate.owner_about"/>
      </div>
    </section>

    <section class="margin-bottom-35">
      <h3 class="font-24">{{ $t('estates.specifications') }}</h3>

      <v-row>

        <!-- Badrooms -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>

            <v-text-field
              dense
              outlined
              :rules="[rules.required]"
              name="bedrooms"
              type="number"
              min="0.5"
              :append-icon="specificationsIcons.rooms"
              :label="$t('estates.props.bedrooms')"
              v-model.number="estate.specifications.rooms"/>
        </v-col>

        <!-- Baths -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>

            <v-text-field
              dense
              outlined
              name="baths"
              type="number"
              min="1"
              :append-icon="specificationsIcons.baths"
              :label="$t('estates.props.baths')"
              v-model.number="estate.specifications.baths"/>
        </v-col>

        <!-- Balocnies -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>

            <v-text-field
              dense
              outlined
              name="balconies"
              type="number"
              min="0"
              :append-icon="specificationsIcons.balconies"
              :label="$t('estates.props.balconies')"
              v-model.number="estate.specifications.balconies"/>
        </v-col>

        <!-- Parkings -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>

            <v-text-field
              dense
              outlined
              name="parkings"
              type="number"
              min="0"
              :append-icon="specificationsIcons.parking"
              :label="$t('estates.props.parking')"
              v-model.number="estate.specifications.parkings"/>
        </v-col>

        <!-- Floor -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
          <v-text-field
            dense
            outlined
            name="floor"
            type="number"
            min="0"
            :append-icon="specificationsIcons.floor"
            :rules="[rules.number]"
            :label="$t('estates.props.floor')"
            v-model.number="estate.specifications.floor"/>
        </v-col>

        <!-- Stories -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>

          <v-text-field
            dense
            outlined
            name="stories"
            type="number"
            min="0"
            :rules="[rules.number]"
            append-icon="mdi-elevator-up"
            :label="$t('estates.props.stories')"
            v-model.number="estate.specifications.stories"/>
        </v-col>

        <!-- Rate Tax -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
          <v-text-field
            dense
            outlined
            append-icon="mdi-cash"
            name="rate_tax"
            :rules="[rules.number]"
            :label="$t('estates.props.rate_tax')"
            clearable
            v-model.number="estate.specifications.rate_tax"/>
        </v-col>

        <!-- House Committee -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
          <v-text-field
            dense
            outlined
            append-icon="mdi-account-cash-outline"
            name="house_committee"
            :rules="[rules.number]"
            :label="$t('estates.props.house_committee')"
            clearable
            v-model.number="estate.specifications.house_committee"/>
        </v-col>

        <!-- Water hitter -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
          <v-select
            dense
            outlined
            multiple
            name="water_hitter"
            :label="$t('estates.props.water_hitter')"
            clearable
            :items="waterHitterParams"
            v-model="waterHitter"/>
        </v-col>

        <!-- Block/Lot -->
        <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4 v-if="fullUpload">
          <v-row>
            <v-col>
              <v-text-field
                dense
                outlined
                append-icon="mdi-map-search-outline"
                name="block"
                :rules="[rules.number]"
                :label="$t('estates.props.block')"
                clearable
                v-model.number="estate.specifications.block_parcel.block"/>
            </v-col>
            <span class="font-24 padding-top-10">/</span>
            <v-col>
              <v-text-field
                dense
                outlined
                append-icon="mdi-map-search-outline"
                name="lot"
                :rules="[rules.number]"
                :label="$t('estates.props.parcel')"
                clearable
                v-model.number="estate.specifications.block_parcel.parcel"/>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </section>

    <section class="margin-bottom-35">
      <h3 class="font-24">{{ $t('estates.features') }}</h3>

      <v-row>
        <v-col cols=12 sm=4 class="no-padding-bottom no-padding-top" v-for="(v, feature) in features" :key="feature">
          <v-switch dense :label="$t(`estates.props.${feature}`)" v-model="features[feature]"/>
        </v-col>
      </v-row>
    </section>

    <FormButtons @next="save" @back="back" v-model="estate"/>
  </v-form>
</template>

<script type="text/javascript">
import FormButtons from '@/app/modules/estates/components/form-components/form-buttons'
import stepperEmit from '@/common/mixins/stepper-emit-mixin.js'
import rules from '@/common/mixins/validations-rules-mixin.js'
import { specificationsIcons, features } from '@/app/modules/estates/utils/estate-props'
import { mapGetters } from 'vuex'

export default {
  name: 'EstateForm',

  data () {
    return {
      estate: this.value,
      waterHitter: [],
      waterHitterParams: [
        {
          text: this.$t('estates.props.sun'),
          value: 'Sun'
        },
        {
          text: this.$t('estates.props.electric'),
          value: 'Electric'
        },
        {
          text: this.$t('estates.props.gas'),
          value: 'Gas'
        }
      ],
      specificationsIcons,
      features
    }
  },

  components: {
    FormButtons
  },

  mixins: [stepperEmit, rules],

  computed: {
    ...mapGetters('user', ['isAgent']),

    fullUpload () {
      return this.isAgent
    },

    storiesToView () {
      return this.$options.filters.round5(parseInt(this.estate.specifications.floor || 0)) + 24
    }
  },

  methods: {
    async save () {
      this.estate.specifications.water_hitter = this.waterHitter.toString() || 'None'

      this.estate.features = Object.keys(this.features).reduce((arr, f) => {
        if (this.features[f]) {
          arr.push(f)
        }
        return arr
      }, []).toString()

      this.$emit('next')
    },

    setWaterHitter () {
      if (this.estate.specifications.water_hitter) {
        this.waterHitter = this.estate.specifications.water_hitter.replace(/\s/g, '').split(',')
      }
    },

    setFeatures () {
      this.estate.features.split(',').forEach((f) => {
        if (this.features.hasOwnProperty(f)) this.features[f] = true
      })
    }
  },

  created () {
    if (!this.estate.specifications.block_parcel) {
      this.estate.specifications.block_parcel = { block: null, parcel: null }
    }
    this.setWaterHitter()
    this.setFeatures()
  }
}
</script>
