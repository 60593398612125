<template>
  <div class="estate-form">
    <v-parallax
      :height="parallaxHight()"
      :src="headerImage()"
      v-if="$vuetify.breakpoint.smAndUp">

      <v-row justify="center" align="center">
        <h1 class="page-header">{{ $t('estates.form.main_title') }}</h1>
      </v-row>
    </v-parallax>

    <v-container class="no-padding">

      <v-stepper v-model="steps">

        <v-stepper-header>
          <v-stepper-step v-for="(step, i) in stepper" :step="i+1" :key="i">{{step}}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>

          <v-stepper-content step="1">
            <GeneralInfo @next="next" @back="back" v-model="estate" :key="steps" v-if="estateLoaded"/>
          </v-stepper-content>

          <v-stepper-content step="2">
            <MoreDetails @next="save"  @back="back" v-model="estate" :key="steps" v-if="estateLoaded"/>
          </v-stepper-content>

          <v-stepper-content step="3">
            <Images @next="done" @back="back" @save="save" v-model="estate" :key="steps" v-if="estateLoaded && steps === 3"/>
          </v-stepper-content>
        </v-stepper-items>

      </v-stepper>
      <Spacer height="35px" v-if="$vuetify.breakpoint.mdAndUp"/>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      color="error">
        <vue-markdown>{{snackMessage}}</vue-markdown>
    </v-snackbar>
  </div>
</template>

<script>
// import EstateType from '@/app/modules/estates/components/form-components/estate-type'
import GeneralInfo from '@/app/modules/estates/components/form-components/general-info'
import MoreDetails from '@/app/modules/estates/components/form-components/more-details'
import Images from '@/app/modules/estates/components/form-components/images'
import stepperMixin from '@/common/mixins/stepper-mixin.js'
import EstatesService from '@/app/modules/estates/estates-service'
import { mapGetters } from 'vuex'

export default {
  name: 'NewEditEstate',

  props: {
    id: {
      type: String,
      default: null
    }
  },

  components: {
    MoreDetails,
    // EstateType,
    GeneralInfo,
    Images
  },

  mixins: [stepperMixin],

  data () {
    return {
      estateLoaded: false,
      estate: {
        address: {},
        specifications: {},
        features: '',
        deal_type: 'rent'
      },
      steps: 1,
      stepper: JSON.parse(this.$t('estates.form.stepper')),
      snackbar: false,
      snackMessage: this.$t('common.error_message')
    }
  },

  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters(['locale'])
  },

  methods: {
    parallaxHight () {
      return (this.$vuetify.breakpoint.name === 'xs') ? 100 : 400
    },

    headerImage () {
      return this.$cloudinary.url('/app_images/hand-house.webp', { width: window.innerWidth, height: this.parallaxHight() + 250, crop: 'fit' })
    },

    setKeyWords () {
      let keywords = this.estate.features.split(',').reduce((acc, key) => {
        acc += ` ${this.$t(`estates.props.${key}`)}`
        return acc
      }, '')

      // if (this.estate.address.city) {
      //   keywords += ` ${this.estate.address.city}`
      // }

      // if (this.estate.address.street) {
      //   keywords += ` ${this.estate.address.street}`
      // }

      // if (!this.estate.address.street && !this.estate.address.city) {
      //   keywords += ` ${this.estate.address.general_address}`
      // }

      keywords += ` ${this.$t(`estates.form.${this.estate.deal_type}`)} ${this.$t(`estates.categories.${this.estate.category}`)} ${this.estate.price} ${this.estate.specifications.balconies ? this.$t('estates.props.balcony') : ''}`

      keywords += ` ${this.estate.title}`

      return [{
        language: this.locale.lang,
        keywords
      }]
    },

    async save (callback = () => {}) {
      this.estate.kewords_by_language = this.setKeyWords()

      try {
        const res = await EstatesService.saveEstate(this.estate)
        this.estate = res
        callback()
        if (this.steps < 3) {
          this.next()
        }
      } catch (e) {
        this.snackbar = true
        console.error(e)
      }
    },

    done () {
      this.$router.push({ name: 'estate', params: { slug: this.estate.slug } })
    }
  },

  async beforeMount () {
    if (this.$route.params.slug) {
      try {
        this.estate = await EstatesService.getEstate(this.$route.params.slug, true)
        if (this.estate.user_id !== this.user.id) {
          this.$router.push({ name: 'home' })
        }
        this.estateLoaded = true
      } catch (e) {
        if (e.status === 401) {
          this.$router.push({ name: 'home' })
        }
      }
    } else {
      // new estate for current user
      this.estate.user_id = this.user.id
      this.estateLoaded = true
    }
  }
}

</script>

<style lang="scss">

.estate-form {

  .container {
    opacity: .975;

    @include sm-and-up {
      margin-top: -74px;
    }
  }

  h1 {
    font-size: $font-3xl;

    @include sm-and-up {
      font-size: $font-4xl;
    }
  }

  form {
    padding: 0px 11px 13px;
  }

  h3 {
    margin-bottom: 24px;
    color: var(--v-secondary-lighten3);
  }

  .v-input--switch {
    margin-top: 0;
  }

  @include xs-only {
    padding-top: 70px;
  }
}

</style>
