/*
  - the upload folder needs to be set in the component
  - after the file(s) upload is done "saveUplodes" methods will be called with Cloudinary uploaded files as params
*/

const mixin = {

  data () {
    return {
      loading: false,
      widgetLoaded: false,
      uploadType: null, // image/floorPlan/video/assessment/tax/virtualTour/tabu
      uploadConfig: {
        cloudName: 'rbns',
        uploadPreset: 'rbns_preset',
        sources: ['local', 'camera', 'instagram', 'facebook'],
        styles: {
          palette: {
            window: this.$vuetify.theme.themes.light.bright,
            windowBorder: this.$vuetify.theme.themes.light.secondary,
            tabIcon: this.$vuetify.theme.themes.light.secondary,
            menuIcons: this.$vuetify.theme.themes.light.secondary,
            textDark: this.$vuetify.theme.themes.light.secondary,
            textLight: this.$vuetify.theme.themes.light.bright,
            link: this.$vuetify.theme.themes.light.secondary,
            // action: '#FF620C',
            inactiveTabIcon: this.$vuetify.theme.themes.light.secondary,
            error: this.$vuetify.theme.themes.light.error,
            inProgress: this.$vuetify.theme.themes.primary // ,
            // complete: '#20B832',
            // sourceBg: '#E4EBF1'
          },
          fonts: {
            '"Assistant", sans-serif': 'https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;600&display=swap'
          }
        }
      },
      uploadConfigCropConfig: {
        cropping: 'true',
        croppingCoordinatesMode: 'custom',
        uploadPreset: 'crop_preset'
      }
    }
  },

  methods: {
    setCloudinaryWidget () {
      let widget = window.cloudinary.createUploadWidget(this.uploadConfig, async (error, result) => {
        if (error) {
          console.error(error)
        } else if (result.event === 'queues-end') {
          const saved = await this.saveUplodes(result.info.files)
          if (saved) {
            setTimeout(() => {
              this.cloudinaryWidget.close()
            }, 1500)
          }
        } else if (result.event === 'close') {
          this.loading = false
        }
      })

      this.widgetLoaded = true
      return widget
    },

    uploadImages (type = 'image', moreConfig = {}) {
      this.uploadType = type
      let config = {
        clientAllowedFormats: ['jpg', 'jpeg', 'webp', 'png'],
        maxImageFileSize: '15728640',
        multiple: this.uploadType === 'image'
      }

      Object.assign(config, moreConfig)
      this.cloudinaryWidget.update(config)
      this.cloudinaryWidget.open()
      this.loading = true
    },

    uploadVideo (moreConfig = {}) {
      this.uploadType = 'video'
      let config = {
        clientAllowedFormats: ['mov', 'mp4', 'mpeg', 'wav'],
        maxVideoFileSize: '150000000',
        multiple: false
      }

      Object.assign(config, moreConfig)
      this.cloudinaryWidget.update(config)
      this.cloudinaryWidget.open()
      this.loading = true
    },

    uploadPdf (moreConfig = {}) {
      this.uploadType = 'assessment'
      let config = {
        clientAllowedFormats: ['pdf'],
        maxVideoFileSize: '4000000',
        multiple: false
      }

      Object.assign(config, moreConfig)
      this.cloudinaryWidget.update(config)
      this.cloudinaryWidget.open()
      this.loading = true
    },

    saveUplodes () {
      console.error('No saveUplodes methods defined for widget callBack!')
    },

    setUploadFolder () {
      if (!this.folder) {
        console.error('Upload folder hasn\'t been set in Component data')
        return
      }
      this.uploadConfig.folder = this.folder
    }
  },

  async created () {
    await this.$loadScript('https://widget.cloudinary.com/v2.0/global/all.js')
    if (this.allowUploadCrop) {
      Object.assign(this.uploadConfig, this.uploadConfigCropConfig)
    }
    this.setUploadFolder()
    this.cloudinaryWidget = this.setCloudinaryWidget()
  }
}

export default mixin
