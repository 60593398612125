<template>
  <section>
    <h3 class="font-24">{{ $t('estates.form.general_info') }}</h3>

    <v-row>
      <!-- Deal -->
      <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
        <v-select
          dense
          outlined
          :rules="[rules.required]"
          name="dealType"
          :label="$t('estates.form.deal_type')"
          v-model="estate.deal_type"
          :disabled="!isAgent"
          :items="dealTypeItems"/>
      </v-col>

      <!-- Category -->
      <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
        <v-select
          dense
          outlined
          name="category"
          :rules="[rules.required]"
          :label="$t('estates.form.category')"
          v-model="estate.category"
          :items="typesForSelect()"
          item-text="text"/>
      </v-col>

      <!-- Address -->
      <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
        <auto-complete  v-model="estate.address"
                        :label="$t('estates.props.address')"
                        :hint="$t('estates.form.address_hint')"
                        @input="setDefaults" />
      </v-col>

      <!-- Sector
      <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
        <v-select
          dense
          outlined
          :rules="[rules.required]"
          name="sector"
          :label="$t('estates.form.sector')"
          v-model="estate.sector"
          :items="sectorItems"
          item-text="text"/>
      </v-col>
      -->

      <!-- Size -->
      <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
        <v-text-field
          dense
          outlined
          :rules="[rules.required]"
          name="size"
          :suffix="$t('estates.form.size_suffix')"
          :label="$t('estates.props.size')"
          v-model.number="estate.specifications.size"/>
      </v-col>

      <!-- Price -->
      <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
        <v-text-field
          dense
          outlined
          :rules="[rules.required]"
          name="price"
          append-icon="mdi-currency-ils"
          :label="$t('estates.props.price')"
          v-model.number="estate.price"/>
      </v-col>

      <!-- Evacuation Date -->
      <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
        <v-menu
          v-model="monthMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">

          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="evacuationDate"
              :label="$t('estates.props.evacuation_date')"
              name="evacuation_date"
              readonly
              v-on="on"
              append-icon="mdi-calendar-month-outline"
              dense
              outlined
              clearable/>
          </template>

          <v-date-picker
            type="month"
            v-model="evacuationDate"
            :min="currnetMonth()"
            @input="monthMenu = false"
            :locale="locale.lang"/>
        </v-menu>
      </v-col>

      <!-- Age -->
      <v-col class="no-padding-top no-padding-bottom" cols=12 sm=4>
        <v-text-field
          dense
          outlined
          name="age"
          v-model.number="estate.age">
          <template slot="label"><span v-html="$t('estates.props.age')"/></template>
        </v-text-field>
      </v-col>
    </v-row>

    <FormButtons @next="moveNext" @back="back" no-back v-model="estate"/>
  </section>
</template>

<script>
import { types as Types } from '@/common/mixins/estates/estate-types-mixin'
import FormButtons from '@/app/modules/estates/components/form-components/form-buttons'
import stepperEmit from '@/common/mixins/stepper-emit-mixin.js'
import dealTypes from '@/common/mixins/estates/deal-types-mixin.js'
import rules from '@/common/mixins/validations-rules-mixin.js'
import autoComplete from '@/common/components/google-auto-complete'
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralInfo',

  components: {
    autoComplete,
    FormButtons
  },

  data () {
    return {
      estate: this.value,
      evacuationDate: this.$moment(this.value.specifications.evacuation_date).format('YYYY-MM'),
      sectorItems: [
        {
          text: this.$t('estates.form.residence'),
          value: 'residence'
        },
        {
          text: this.$t('estates.form.commercial'),
          value: 'commercial'
        }
      ],
      monthMenu: false,
      gglAddress: null,
      autocomplete: null
    }
  },

  mixins: [stepperEmit, rules, dealTypes],

  computed: {
    ...mapGetters('user', ['isAgent']),
    ...mapGetters(['locale'])
  },

  methods: {
    typesForSelect () {
      return Object.keys(Types).reduce((types, k) => {
        types.push({
          text: this.$t(`estates.categories.${k}`),
          value: k
        })
        return types
      }, [])
    },

    suggestedTitle () {
      var city = ''
      const category = this.estate.category ? this.$t(`estates.categories.${this.estate.category}`) : ''
      const deal = this.estate.deal_type ? `${this.$t('estates.form.for_deal_type')}${this.$t(`estates.form.${this.estate.deal_type}`)}` : ''
      if (this.estate.address.street && this.estate.address.city) {
        city = `${this.$t('estates.form.in_for_city')}${this.estate.address.street} ${this.estate.address.city}`
      } else if (this.estate.address.general_address) {
        city = `${this.$t('estates.form.in_for_city')}${this.estate.address.general_address}`
      }
      return `${category} ${deal} ${city}`
    },

    setDefaults () {
      if (/(gardenApartment)|(land)/.test(this.estate.category) && !/(garden)/.test(this.estate.features)) {
        this.estate.features += 'garden'
      }

      if (this.estate.title) return
      this.estate.title = this.suggestedTitle()
    },

    setEvacuationDate () {
      this.estate.specifications.evacuation_date = new Date(this.evacuationDate)
    },

    currnetMonth () {
      return new Date().toISOString()
    },

    moveNext () {
      this.setEvacuationDate()
      this.next()
    }
  }
}
</script>
